import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
// import Instagram from '../../components/instagram';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/culture/culture.module.scss';
import SpotLogo from '../../images/culture/spot.gif';

class Culture extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headingCulture = document.querySelector('.init_a_headingCulture');
    const introCulture = document.querySelector('.init_a_introCulture');
    setTimeout(() => {
      this.initAnimation.heading(headingCulture);
      this.initAnimation.intro(introCulture);
    }, 1000);
  }

  lifeAtBankto = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const lifeAtBanktoItem = document.querySelectorAll('.init_a_lifeAtBanktoItem');
      this.initAnimation.list(lifeAtBanktoItem);
      unobserve();
    }
  };

  rule = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingRule = document.querySelector('.init_a_headingRule');
      const introRule = document.querySelector('.init_a_introRule');
      this.initAnimation.heading(headingRule);
      this.initAnimation.intro(introRule);
      unobserve();
    }
  };

  effort = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingEffort = document.querySelector('.init_a_headingEffort');
      const effortListItem = document.querySelectorAll('.init_a_effortListItem');
      this.initAnimation.heading(headingEffort);
      this.initAnimation.list(effortListItem);
      unobserve();
    }
  };

  joinOurTeam = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingJoinOurTeam = document.querySelector('.init_a_headingJoinOurTeam');
      const introJoinOurTeam = document.querySelector('.init_a_introJoinOurTeam');
      const joinOurTeamFigureBg = document.querySelectorAll('.init_a_joinOurTeamFigure_bg');
      const joinOurTeamFigureImg = document.querySelectorAll('.init_a_joinOurTeamFigure_img');
      this.initAnimation.heading(headingJoinOurTeam);
      this.initAnimation.intro(introJoinOurTeam);
      this.initAnimation.slider(joinOurTeamFigureBg, joinOurTeamFigureImg);
      unobserve();
    }
  };

  render() {
    const CultureQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/illustration04.png" }){
          childImageSharp {
            fluid(maxWidth: 1080, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        illustration01: file(relativePath: { eq: "illustration/illustration01.png" }){
          childImageSharp {
            fluid(maxWidth: 450, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        illustration02: file(relativePath: { eq: "illustration/illustration04_sub.png" }){
          childImageSharp {
            fluid(maxWidth: 410, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options2 = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={CultureQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="culture" />
              <main className={`culture ${Style.culture}`}>
                <section className={`top ${Style.top}`}>
                  <div className={Style.left}>
                    <h1 className="heading">
                      <span className="text">Life at<br className="spTabHDisBlock" /> bankto</span>
                      <span className="clip init_a_headingCulture">Life at<br className="spTabHDisBlock" /> bankto</span>
                    </h1>
                    <p className="intro init_a_introCulture">学ぶことに情熱をもつ人や、新たな価値のために挑戦できる人を尊重しています。個々人の才能やスキルを活かすための研修や制度、その根底にある行動規範や風土、文化を大切にしています。</p>
                  </div>
                  <figure className={Style.illustration}>
                    <Img
                      className={Style.illustrationImg}
                      fluid={data.illustration.childImageSharp.fluid}
                      alt="Life at  bankto"
                    />
                  </figure>
                </section>
                <Observer {...options} onChange={this.lifeAtBankto}>
                  <section className={`sectionContent sectionContentFirst ${Style.lifeAtBankto}`}>
                    <div className="scroll scrollTop" />
                    <div className="inner">
                      <ul className={Style.lifeAtBanktoList}>
                        <li className={`init_a_lifeAtBanktoItem ${Style.lifeAtBanktoItem}`}>
                          <h2>
                            オルタナティブな
                            <br />
                            価値を提示する
                          </h2>
                          <p>バンクトゥでは「オルタナティブ」な思考や態度を評価します。既成の価値観に縛られず、「もう一つの選択肢」を提示できる人を尊重しています。そのため、年齢や性別、キャリアや経験に関係なく、個々人が日々の暮らしのなかで得た感覚や知見を信じ、素直に自らの考えや意見を表明できる環境を維持できるよう努めています。</p>
                        </li>
                        <li className={`init_a_lifeAtBanktoItem ${Style.lifeAtBanktoItem}`}>
                          <h2>
                            個人の成長を
                            <br />
                            組織の価値に
                          </h2>
                          <p>事業活動において最も重要なことは、労働を通じて個々人が「成長を実感する」ことです。人間は、生命活動を行うためだけに生きてはいません。法人もまた営利のためだけに生きることは、ひとつの貧しさです。事業活動の中で、どのような行動を実践できるのか。そして、その過程で得られる個人の成長は、組織の大きな存在意義です。</p>
                        </li>
                        <li className={`init_a_lifeAtBanktoItem ${Style.lifeAtBanktoItem}`}>
                          <h2>
                            関係の質を
                            <br />
                            構築する
                          </h2>
                          <p>人間的かつ文化的に信頼できるコミュニティがなければ、いいアウトプットを生み出すことは難しいと考えています。スタッフ間はもちろん、クライアントやパートナーといったプロジェクトに関わるすべての人と、より良い関係を築けるかを意識的に考えています。関係の質が、思考や行動に影響を与え、価値のある結果へ繋がります。</p>
                        </li>
                      </ul>
                    </div>
                  </section>
                </Observer>
                <Observer {...options2} onChange={this.rule}>
                  <section className={`sectionContent ${Style.rule}`}>
                    <div className={Style.inner}>
                      <div className={Style.right}>
                        <h2 className={`heading ${Style.heading}`}>
                          <span className="text">「路地的。」を<br />実践するための<br />10のルール</span>
                          <span className="clip init_a_headingRule">「路地的。」を<br />実践するための<br />10のルール</span>
                        </h2>
                        <p className="intro init_a_introRule">「路地的。」とは、バンクトゥの行動規範です。都市における「路地」という空間がもつ価値や役割を踏まえ、事業の価値判断やアクションに活かしています。</p>
                        <p className={`btn ${Style.comingSoonBtn}`}>
                          {/* <TransitionLink to="/culture" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}> */}
                            {/* <span>Read message</span> */}
                            <span>Coming soon!</span>
                          {/* </TransitionLink> */}
                        </p>
                      </div>
                      <div className={Style.ruleFigure}>
                        <Img
                          fluid={data.illustration01.childImageSharp.fluid}
                          alt="「路地的。」を実践するための10のルール"
                        />
                      </div>
                    </div>
                  </section>
                </Observer>
                <Observer {...options2} onChange={this.effort}>
                  <section className={`sectionContent ${Style.effort}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.left}>
                        <h2 className={`heading ${Style.heading}`}>
                          <span className="text">個の成長を支援する<br />研修やサポート体制を<br />整えています</span>
                          <span className="clip init_a_headingEffort">個の成長を支援する<br />研修やサポート体制を<br />整えています</span>
                        </h2>
                      </div>
                      <figure className={Style.effortFigure}>
                        <Img
                          fluid={data.illustration02.childImageSharp.fluid}
                          alt="個の成長を支援する研修やサポート体制を整えています"
                        />
                      </figure>
                    </div>
                    <div className="inner">
                      <ul className={Style.effortList}>
                        <li className={`init_a_effortListItem ${Style.effortItem}`}>
                          <h3>ルーキーシップ研修</h3>
                          <p>転職者や新卒者に対して研修を行い、初期段階で生じる疑問や不安が解消できるように努めています。社内のスタッフが講師として立ち、ウェブやメディアの基礎リテラシー、デザイン、プロジェクトマネジメントなどの講義を担当。業界や職種への理解や、キャリア意識向上のきっかけづくりを促進しています。</p>
                        </li>
                        <li className={`init_a_effortListItem ${Style.effortItem}`}>
                          <h3>ライフキャリア形成</h3>
                          <p>会社から業務を一方的に押し付けるのではなく、個々人が目標を定め、成長を実感できることを何よりも大切にしています。一人ひとりがどう働きたいのか、何に情熱をもっているのかを共有するため、すべてのスタッフが「短中長期目標」を設定し、会社の目標と個人のキャリアをすり合わせる機会を定期的に設けています。</p>
                        </li>
                        <li className={`init_a_effortListItem ${Style.effortItem}`}>
                          <h3>ラーニング・サポート</h3>
                          <p>自律的に学ぶ人や行動する人を尊重し、可能な限りのサポートを行なっています。チームごとに活用できる予算を設け、経営層に許可なく、専門的な資料や機器の購入、セミナー等への参加が可能です。また、他企業への長期研修、セミナー等への参加に対して費用負担を行うなど、積極的な支援を行っています。</p>
                        </li>
                      </ul>
                    </div>
                  </section>
                </Observer>
                <Observer {...options2} onChange={this.joinOurTeam}>
                  <section className={`sectionContent ${Style.joinOurTeam}`}>
                    <figure className={`init_a_joinOurTeamFigure ${Style.joinOurTeamFigure}`}>
                      <div className="init_a_joinOurTeamFigure_bg" />
                      <img
                        className="init_a_joinOurTeamFigure_img"
                        src={SpotLogo}
                        alt="spot"
                      />
                    </figure>
                    <div className={Style.inner}>
                      <div className={Style.left}>
                        <h2 className={`heading ${Style.heading}`}>
                          <span className="text">スケートボードの<br />ウェブメディアを<br className="pcDisBlock" />立ち上げました。</span>
                          <span className="clip init_a_headingJoinOurTeam">スケートボードの<br />ウェブメディアを<br className="pcDisBlock" />立ち上げました。</span>
                        </h2>
                        <p className="intro init_a_introJoinOurTeam">バンクトゥでは、スケートボードメディア「Spot skateboarding」を運営しています。「バンクトゥ」という社名は、ストリートにある土手（バンク）に由来しています。</p>
                        {/* <p className="btn">
                          <TransitionLink to="/recruit" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <span>Join our team</span>
                          </TransitionLink>
                        </p> */}
                        <p className={Style.link}>
                          <a
                            href="https://spotskateboarding.jp/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://spotskateboarding.jp
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 17 14"
                            >
                              <rect x="4.5" y="0.5" width="12" height="9" />
                              <path d="M16,1v8H5V1H16 M17,0H4v10h13V0L17,0z" />
                              <rect x="0.5" y="4.5" width="12" height="9" />
                              <path d="M12,5v8H1V5H12 M13,4H0v10h13V4L13,4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </section>
                </Observer>
                {/*<Instagram isHeading />*/}
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Culture;
